<template>
  <div class="z-30 my-2 w-full px-4">
    <div class="flex w-full flex-wrap items-center gap-2">
      <slot />

      <div class="hidden md:ml-auto md:block" />

      <slot name="end" />
    </div>
  </div>
</template>
